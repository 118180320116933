import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { validate } from 'lib/utils-library';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultipleSearchSelect = ({
  d3Data, items, value = [], onChange = () => {}, onBlur = () => {}, err = '',
}) => {
  const [currValue, setValue] = useState(value || d3Data.defaultValue || []);
  const [error, setError] = useState(err || null);

  useEffect(() => {
    setValue(value || d3Data.defaultValue || [])
  }, [d3Data.defaultValue]);
  const handleChange = (e, val) => {
    setValue(val);
    onChange(val);
    const newError = validate(val, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };
  const handleBlur = (e, val) => {
    onBlur(val);
    const newError = validate(currValue, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
  };
  return (
    <Autocomplete
      multiple
      key={d3Data.key}
      value={currValue}
      onChange={handleChange}
      id="tags-outlined"
      onBlur={handleBlur}
      options={items}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={d3Data.name}
          type={d3Data.type}
          helperText={err || error}
          error={Boolean(err) !== false || error != null}
        />
      )}
    />
  );
};

export default MultipleSearchSelect;

MultipleSearchSelect.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

MultipleSearchSelect.defaultProps = {
  value: [],
  onBlur: () => null,
  err: '',
};
