import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
  Checkbox, ListItemText, ListItemIcon,
} from '@mui/material';
import styles from './MultiSelectFilter.module.css';

// temporary utility function
import { getFilteredData } from '../../data/rowData';
import { mls } from 'lib/multilanguagesupport';

const MultiSelectFilter = (props, ref) => {
  const [value, setValue] = useState([]);
  const columnName = props.column.colId;

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => ({
    doesFilterPass(params) {
      return true;
    },

    isFilterActive() {
      return value != null && value.length !== 0 && value.length !== props.options.length;
    },

    getModel() {
      return { filter: value.map((valObj) => valObj.value) };
    },

    setModel(model) {
      setValue(model.value || []);
    },
  }));

  const handleChange = (val) => {
    console.log(val, 'propsssvalueee');
    setValue(val);
  };

  useEffect(() => {
    props.filterChangedCallback();
    // props.columnData.handleFilterChange({
    //   isActive: value != null && value.length !== 0 && value.length !== props.options.length,
    //   columnName,
    //   value: value.map((valObj) => valObj.value ?? valObj),
    // });
    // console.log(props, 'propsss');
    // fetch filtered data from backend
    // const rowData = [];//  getFilteredData(columnName, value);
    
    // set row data to filtered data
    // props.api.setRowData(rowData);
  }, [columnName, props, value]);

  return (
    <div className={styles.mainContainer}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="multi-select-filter-label">{mls(props.columnData?.name)}</InputLabel>
        <Select
          id="multi-select-filter"
          labelId="multi-select-filter-label"
          multiple
          // open --> causing error bcoz of filter-popup
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          renderValue={(values) => values.map((valObj) => valObj.label ?? valObj).join(', ')}
        >
          {props.options.map((option) => (
            <MenuItem size="samll" className="ag-custom-component-popup" key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={value.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={mls(option?.label ?? option)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default forwardRef(MultiSelectFilter);
