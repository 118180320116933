import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField } from '@mui/material';
import ErrorToolTip from '../../ErrorTooltip';
import { validate } from 'lib/utils-library';

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(null);

  const handleChange = (val) => {
    setValue(val);
    const newError = validate(val, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };
  const handleBlur = (val) => {
    const newError = validate(val, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    }
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    afterGuiAttached: () => {},
  }));

  return (
    <ErrorToolTip
      open={Boolean(error)}
      title={error == null ? '' : error}
      arrow
    >
      <input
        className='form-control-plaintext'
        style={{ zIndex: 5 }}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        type={props.columnData.type}
        value={value}
        error={Boolean(error)}
        autoFocus
      />
    </ErrorToolTip>
  );
});
