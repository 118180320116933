import { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import CreateUpdateWrapper from './CreateUpdateWrapper';
import { useHistory } from 'react-router-dom';

function CreateModal({
  setModalOpen,
  modalOpen,
  tableConfig,
  component,
  createData,
  updateWithData,
  isUpdate,
  setRowsSelected,
  parentScreen,
  parentRowID,
  relationSchema = {},
}) {
  const history = useHistory();
  
  const handleHideModal = () => {
    setModalOpen(false);
    history.replace(window.location);
  };

  console.log(updateWithData, 'updateWithData')
  return (
    <Modal show={modalOpen} onHide={handleHideModal} size='xl'>
      {/* for has many create and update header in modal */}
      {
          !parentScreen && handleHideModal &&
          <div class="modal-header ms-2">
            <h2 class="modal-title">
              {relationSchema.displayName}
              {
                updateWithData?.id
                ? (
                  <span className="text-muted">
                  { ` | #ID: ${updateWithData?.id}`}
                </span>
                )
                : ''
              }
            </h2>
            
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleHideModal}></button>
          </div>
        }
      <CreateUpdateWrapper
        tableConfig={tableConfig}
        component={component}
        createData={createData}
        isUpdate={isUpdate}
        handleHideModal={handleHideModal}
        setRowsSelected={setRowsSelected}
        parentScreen={parentScreen}
        relationSchema={relationSchema}
        rowID={updateWithData?.id}
        parentRowID={parentRowID}
      />
    </Modal>
  );
}

export default CreateModal;
