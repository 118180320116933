import { mls } from 'lib/multilanguagesupport';
import { removeNonKeyChars } from 'lib/utils-library';
import CellChips from '../Components/CellChip';
import CellText from '../Components/CellText';
import {
  AutoCompleteEditor, TextEditor, DateEditor, SwitchEditor,
} from '../Editors';
import { MultiSelectFilter, DateFilter, MultiSelectFloatingFilter } from '../Filters';

// As columns can be shifted, this function returns the current first column
function isFirstColumn(params) {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

// field of column will be the key if the key is not present,
// name will be converted into the field by removeNonKeyChars
function getField(columnName, name) {
  if (columnName) { return columnName; }
  return removeNonKeyChars(name);
}

export const defaultColDef = {
  sortable: true,
  filter: true,
  floatingFilter: true,
  flex: 1,
  resizable: true,
  checkboxSelection: isFirstColumn,
  headerCheckboxSelection: isFirstColumn,
  minWidth: 150,
  maxWidth: 550,
};

export const frameworkComponents = {
  autoComplete: AutoCompleteEditor,
  switch: SwitchEditor,
  textEditor: TextEditor,
  dateEditor: DateEditor,
  multiSelectFilter: MultiSelectFilter,
  dateFilter: DateFilter,
  multiSelectFloatingFilter: MultiSelectFloatingFilter,
  cellText: CellText,
  cellChips: CellChips,
};

function getCellRendrer(dataType) {
  switch (dataType) {
    case 'switch':
      return 'cellChips';
    case 'checkbox':
      return 'cellChips';
    default:
      return 'cellText';
  }
}

export function checkD3DataType(dataType) {
  if (dataType.startsWith('__d3__')) {
    return true;
  }
  return false;
}

export const getColumnDef = (column, componentID, parentScreen) => {
  const {
    name, columnName, dataType, selectMenu = null, pinned = null, editable = true,
  } = column;

  const cellClass = [];
  let headerClass;
  let floatingFilterComponent = null;
  let filter = 'agTextColumnFilter';

  let filterParams = {
    // comparator: (params) => {
    //   console.log(params, 'filtermodel propsssfilter ');
    // },
    allowTwoConditions: false,
    columnData: column,
    newRowsAction: 'keep',
  };
  let cellEditor = 'textEditor';
  let cellEditorParams = { columnData: column, componentID, parentScreen };

  switch (dataType) {
    case 'password':
      return null;
    case '__d3__CreatedAtDate':
    case '__d3__CreatedAtTime':
    case '__d3__UpdatedAtDate':
    case '__d3__UpdatedAtTime':
    case 'date':
    case 'datePicker':
      cellEditor = 'dateEditor';
      filter = 'dateFilter';
      break;
    case 'number':
    case 'calc':
    case 'currency':
      if (columnName !== 'id') {
        cellClass.push('align-right');
        headerClass = 'ag-right-aligned-header';
      }
      filter = 'agNumberColumnFilter';
      break;
    case 'switch':
    case 'checkbox':
      cellClass.push('align-center');
      headerClass = 'align-center';
      cellEditor = 'switch';
      filter = 'multiSelectFilter';
      filterParams = {
        ...filterParams,
        options: [
          { value: true, label: 'true' },
          { value: false, label: 'false' }
          // , { value: null, label: 'null' }
        ],
      };
      break;
    case 'autoComplete':
    case 'select':
      cellEditor = 'autoComplete';
      filter = 'multiSelectFilter';
      filterParams = {
        ...filterParams,
        options: selectMenu,
      };
      // floatingFilterComponent = 'multiSelectFloatingFilter';
      break;
    default:
      cellClass.push('align-left');
      headerClass = 'ag-left-aligned-header';
      break;
  }

  if (editable) cellClass.push('editable');
  // else cellClass.push('non-editable');
  
  return {
    headerName: mls(name),
    field: getField(columnName, name),
    editable,
    singleClickEdit: true,
    pinned,
    headerClass,
    cellClass,
    cellEditor,
    cellEditorParams,
    filter,
    filterParams,
    floatingFilterComponent,
    // suppressClickEdit: true,
    cellRenderer: getCellRendrer(dataType),
  };
};
