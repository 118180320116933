import { mls } from 'lib/multilanguagesupport';
import { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '_metronic/helpers';
import { ManageCreateData } from 'lib/input-components';
import useOperations from 'screenComponents/useOperations';
import Loading from 'app/modules/errors/components/Loading';
import { useHistory } from 'react-router-dom';

function CreateUpdateWrapper({
  setModalOpen,
  tableConfig,
  component,
  parentScreen = true,
  relationSchema = {},
  handleHideModal,
  rowID = null,
  parentRowID,
}) {
  const [error, setError] = useState({});
  const [updateData, setUpdateData] = useState(0);
  const isCreate = relationSchema.type === 'hasOne'? false : !!!rowID;
  const operations = useOperations(component, rowID, isCreate, relationSchema, parentRowID);
  const dataRef = useRef({});
  const createData= operations.createData;
  const history = useHistory();
  console.log(tableConfig, 'tableConfig')
  useEffect(() => {
    dataRef.current = operations.data?.rows?.[0] || {};
    setUpdateData(updateData + 1);
  },[operations.data?.rows]);

  const handleCreate = async () => {
    if (dataRef.current.id) {
      delete dataRef.current.id;
      operations.updateData(dataRef.current, rowID, false);
    } else {
      // creating data and replacing the URL with the new fetched rowID.
      const res = await createData(dataRef.current);
      if (res.id && parentScreen) {
        history.replace(`${window.location.pathname}?__d3__rowID=${res.id}`);
      }
    }
    if (relationSchema?.type === 'hasMany') {
      handleHideModal();
    }
  };

  if (operations.isLoading) return <Loading />;
  return (
      <div>
        {/* for one to one header */}
        {!relationSchema.displayName && !handleHideModal && <h2 class="modal-title px-lg-10">{relationSchema.displayName}</h2>}
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='d-flex flex-column-fluid'>
            <div className='row gy-5 g-xl-8 col-md-12'>
              <ManageCreateData
                columnSchema={tableConfig?.columnSchema || []}
                dataRef={dataRef}
                error={error}
              />
            </div>
          </div>

          <div className='flex-row-fluid py-lg-5 p x-lg-15'>
            <div className='d-flex flex-stack pt-3'>
              <div>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary me-3'
                  onClick={handleCreate}
                >
                  <span className='indicator-label'>
                    {!!rowID ? mls('Update') : mls('Create')}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CreateUpdateWrapper;
