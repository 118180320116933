import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import './style.css';
import Popup from '../Popup';
import { checkD3DataType, defaultColDef, frameworkComponents, getColumnDef } from './utlis';
import { agGridModelToServerFilter } from '../Filters/agGridModelToServerFilter';
import { mls } from 'lib/multilanguagesupport';
import useValidateResource from 'roles/validateResource';
import constants from 'commons/constants';

// sample row-data

const AgGrid = ({ tableSchema, setRowsSelected, rowData, updateData, selectedNodesRef, filterData, setFilterData, setEditable, componentID, parentScreen }) => {
  // ag-grid api's
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [toggle, setToggle] = useState(false);
  const filterModel = useRef({});
  const validateResource = useValidateResource();

  // checks whether to show popup or not
  const [showPopup, setShowPopup] = useState(false);

  // schema and buttons received in the props
  const { columnSchema, tableID } = tableSchema;

  let columnSchemaWithID = Object.assign([], columnSchema);
  columnSchemaWithID.unshift({ name: '#id', columnName: 'id', dataType: 'number', editable: false, pinned: 'left' });
  columnSchemaWithID = columnSchemaWithID.filter((column) => column.dataType!== 'belongsTo');
  // this function sets the rowsSelected to true
  // if one or more rows are selected
  const onSelectionChanged = (params) => {
    const selectedNodes = params.api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      setRowsSelected(false);
      selectedNodesRef.current = [];
    }
    else {
      setRowsSelected(true);
      selectedNodesRef.current = selectedNodes.map((node) => {
        return node.data.id;
      });
    }
    // if there is only 1 row selected then only it can be edited.
    if (selectedNodesRef.current.length === 1) {
      setEditable(selectedNodes[0].data);
    } else setEditable(false);
  };

  // call api to delete data
  const deleteData = (event) => {
    // console.log(event, 'Data Deleted!');
    setShowPopup(false);
  };

  useEffect(() => {    
    if (columnSchemaWithID.length > 5 && columnApi) {
      columnApi.autoSizeAllColumns();
      setToggle(!toggle);
    }
    if (Object.keys(filterData?.where || {}) === 0) {
      gridApi.setFilterModel({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, filterData]);

  // const autoSizeAll = (gridColumnApi) => {
  //   var allColumnIds = [];
  //   gridColumnApi.getAllColumns().forEach(function(column) {
  //     allColumnIds.push(column.colId);
  //   });
  //   gridColumnApi.autoSizeColumns(allColumnIds);
  // }
  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (columnSchemaWithID.length > 5) {
      // params.columnApi.autoSizeAllColumns();
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      }, 3000);
      
    } else {
      // params.api.sizeColumnsToFit()
    }
  };

  const onFilterChanged = () => {
    // console.log(gridApi.getFilterModel(),'filtermodel bro');
    const newFilterModel = gridApi.getFilterModel();
    filterModel.current = (newFilterModel);
    setFilterData({ ...filterData, where: agGridModelToServerFilter(newFilterModel, columnSchemaWithID) });
  };
  const onSortChanged = () => {
    const order = [];
    const columnsState = columnApi?.getColumnState();
    (columnsState || []).forEach((column) => {
      if (column.sort) {
        let columnType;
        columnSchemaWithID.forEach((columnObj) => {
          if (column.colId === columnObj.columnName) {
            columnType = columnObj.dataType;
          }
        });
        switch (columnType) {
          case '__d3__CreatedAtDate':
            order.push(['__d3__createdAt', column.sort.toUpperCase()]);
            break;
          case '__d3__CreatedAtTime':
            order.push(['__d3__createdAt', column.sort.toUpperCase()]);
            break;
          case '__d3__UpdatedAtDate':
            order.push(['__d3__updatedAt', column.sort.toUpperCase()]);
            break;
          case '__d3__UpdatedAtTime':
            order.push(['__d3__updatedAt', column.sort.toUpperCase()]);
            break;

          default:
            order.push([column.colId, column.sort.toUpperCase()]);
            break;
        }
      }
    });
    setFilterData({ ...filterData, order });
    // console.log(columnsState,'filtermodel brosort');
  }

  return (
    <>
      <Popup setShowPopup={setShowPopup} handleDelete={deleteData} showPopup={showPopup} />
      {/* <Alert color="primary" isOpen={infoDismis} toggle={() => onDismiss(false)} onClick={() => onDismiss(false)}>
        {mls('Double click on any table cell to edit.')}
      </Alert> */}
      <div className="ag-theme-alpine shadow-none card" id="ag-grid-container" style={{ minWidth: 'auto', maxHeight: '65vh', height: '1024px', minHeight: '300px' }}>
        {/* Table Grid according to the passed schema */}
        <AgGridReact
          height="100%"
          width="100%"
          rowData={rowData}
          rowSelection="multiple"
          rowHeight={42}
          suppressRowClickSelection
          // suppressRowHoverHighlight
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          enableRangeSelection
          // singleClickEdit={false}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          isPopup
          enableFilter
          onCellValueChanged={(cellValue) => updateData({ [cellValue.colDef.field]: cellValue.newValue }, cellValue.data.id)}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          overlayLoadingTemplate={
            `<span class="ag-overlay-loading-center">${mls('Please wait while your rows are loading')}</span>`
          }
          overlayNoRowsTemplate={
            `<span class="ag-overlay-loading-center">${mls('Please wait while your rows are loading')}</span>`
          }
          onCellClicked={() => {
            /**
             * @todo check autosizeAll columns while the cell is editable.
             */
            columnApi && columnApi.autoSizeAllColumns();
          }}
        >
          {
            columnSchemaWithID.map((column) => {
              if (validateResource(constants.resourceType.TABLE, tableID, constants.resourceType.COLUMN_READ, column.columnName) === false) {
                // no read access
                return null;
              }
              // check for update access. if Parent Screen = true then editable is false, inner screen are editable.
              let editable = !parentScreen;
              
              const updateAccess = validateResource(constants.resourceType.TABLE, tableID, constants.resourceType.COLUMN_UPDATE, column.columnName);
              // if user is granted access to edit via role then it will bypass the readOnly rule.
              if (!parentScreen && (checkD3DataType(column.dataType) || updateAccess === false || (!updateAccess && column.readOnly === true))) {
                editable = false;
              }
              const columnDef = getColumnDef({ editable,...column }, componentID, parentScreen);
              return columnDef ? (
                <AgGridColumn key={column.name} {...columnDef} />
              ) : null;
            })
          }
        </AgGridReact>
      </div>
    </>
  );
};

export default AgGrid;

AgGrid.propTypes = {
  tableSchema: PropTypes.shape({
    type: PropTypes.string,
    moduleName: PropTypes.string,
    submoduleName: PropTypes.string,
    submoduleID: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    columnSchema: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    buttons: PropTypes.array,
  }).isRequired,
  defaultPageSize: PropTypes.number,
};

AgGrid.defaultProps = {
  defaultPageSize: 10,
};
