
const API = 'https://test.supista.com/api'; //'http://127.0.0.1/api' //  'http://localhost:8080/api';

export const supistaAuthApiPost = async (endpoint, data, method = 'POST') => {
  const url = new URL(`${API}/${endpoint}`);
  const lang = localStorage.getItem('__d3__language') || localStorage.getItem('__d3__defaultLanguage') || 'English';
  if (method === 'GET') {
    url.searchParams.append('data', JSON.stringify(data));
    url.searchParams.append('lang', lang);
  }
  const dataToSend = { ...data, lang };

  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: method !== 'GET' ? JSON.stringify(dataToSend) : undefined,
  }).then(async (response) => {
    const response2 = response.clone();
    try {
      const jsonRes = await response.json();
      return jsonRes;
    } catch (err) {
      console.error(err, 'catched error');
      return response2.text();
    }
  });
};

export const getURL = (endpoint, data) => {
  const url = new URL(`${API}/${endpoint}`);
  const lang = localStorage.getItem('__d3__language') || localStorage.getItem('__d3__defaultLanguage') || 'English';
  url.searchParams.append('data', JSON.stringify(data));
  url.searchParams.append('lang', lang);
  return url;
}
