/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useState } from 'react';
import { Redirect, Switch, Route, useHistory, withRouter } from 'react-router-dom';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import getAppID from 'utils/getAppID';
import getDefaultAppID from 'utils/getDefaultAppID';
import ScreenComponent from 'screenComponents';
import { useDispatch } from 'react-redux';
import { create } from 'redux/stateSlice';

/**
 * @todo: remove the below with the redux user data.
 */
import useAuthenticate from 'auth/authenticate';
import { AuthPage } from 'lib/auth-ui/AuthPage';
import useStoreSchema from 'commons/useStoreSchema';
import reduxConstants from 'redux/reduxConstants';
import { supistaAuthApiPost } from 'network/authConnections';
import { mls } from 'lib/multilanguagesupport';
import getDeviceLanguage from 'lib/multilanguagesupport/getDeviceLanguage';
import getUserAccessSchema from 'roles/getUserAccessSchema';
import PluginRouteHandler from 'projectPlugins/Routes';

const Routes: FC = () => {
  const history = useHistory();
  const authenticate = useAuthenticate();
  const dispatch = useDispatch();

  const userData = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.LOGIN);
  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  let appID = getAppID(history.location.pathname);
  const defaultAppID = getDefaultAppID(userData);

  const fetchMLS = async (lang: string | null | undefined) => {
    try {
      const currentLanguage = lang || localStorage.getItem('__d3__language') || getDeviceLanguage();
      if (currentLanguage) {
        const appMLS = await supistaAuthApiPost(`appMLS/${appID}/${currentLanguage}`, {}, 'GET');
        dispatch(
          create({
            setPath: reduxConstants.config.APP_MLS,
            value: appMLS,
          })
        );
        localStorage.setItem('__d3__languageUpdate', 'true');
      }
    } catch (error) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls(
              'There was a problem while fetching App Current Language, please try again later!'
            ),
            time: 5,
            isActive: true,
          },
        })
      );
    }
  };

  const fetchAppData = async () => {
    const appID = defaultAppID;
    if (!defaultAppID) {
      return;
    }
    try {
      const appSchema = await supistaAuthApiPost(`appSchema/${appID}`, {}, 'GET');
      dispatch(
        create({
          setPath: reduxConstants.config.APP_SCHEMA,
          value: appSchema,
        })
      );
      if (appSchema?.app) {
        // setting up language
        localStorage.setItem(
          '__d3__allLanguages',
          JSON.stringify(appSchema.app?.activeLanguages) || "['English']"
        );
        localStorage.setItem('__d3__defaultLanguage', appSchema.app?.defaultLanguage || 'English');
        if (
          !localStorage.getItem('__d3__language') &&
          appSchema.app?.defaultLanguage !== 'English' &&
          getDeviceLanguage() !== 'English'
        ) {
          localStorage.setItem(
            '__d3__language',
            getDeviceLanguage() || appSchema.app?.defaultLanguage
          );
          fetchMLS(getDeviceLanguage() || appSchema.app?.defaultLanguage);
        }
        // setting up user roles from Schema.
        const userAccessSchema = getUserAccessSchema(
          appSchema.appAccess,
          userSchema.allRoles?.[appID]?.role?.[0] || '__d3__public'
        );
        dispatch(
          create({
            setPath: reduxConstants.config.USER_ACCESS_SCHEMA,
            value: userAccessSchema,
          })
        );
      }
    } catch (error) {
      console.log(error, ' while fetching App Schema,');

      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while fetching App Schema, please try again later!'),
            time: 5,
            isActive: true,
          },
        })
      );
    }
  };

  // fetching the latest app Data.
  useState(() => fetchAppData());
  useState(() => fetchMLS(null));

  if (authenticate !== true) {
    return <Route component={AuthPage} />;
  }

  if (!appID || appID !== defaultAppID) {
    appID = getDefaultAppID(userData);
    return <Redirect to={`/${appID}`} />;
  }

  if (!appSchema?.app) {
    fetchAppData();
    return <></>;
  }
  return (
    <Switch>
      <Route path='/error' component={ErrorsPage} />
      <Route
        key={history.location.key}
        path='/:appID/app/:projectID/:screenGroupID/:screenID/:InnerViewComponentID'
        component={ScreenComponent}
      />
      <Route
        key={history.location.key}
        path='/:appID/app/:projectID/:screenGroupID/:screenID'
        component={ScreenComponent}
      />
      <Route
        key={history.location.key}
        path='/:appID/app/:projectID/:screenGroupID'
        component={ScreenComponent}
      />
      <Route key={history.location.key} path='/:appID/app/:projectID' component={ScreenComponent} />

      {/* plugins */}
      <Route
        key={history.location.key}
        path='/:appID/plugins/:projectID/:pluginType/:pluginID/:connectedScreenGroupID'
        component={PluginRouteHandler}
      />
      <Route
        key={history.location.key}
        path='/:appID/plugins/:projectID/:pluginType/:pluginID'
        component={PluginRouteHandler}
      />
      <Route key={history.location.key} path='/:appID/app/:projectID' component={ScreenComponent} />

      <Route key={history.location.key} path='/:appID/app' component={ScreenComponent} />
      <Route key={history.location.key} path='/:appID' component={ScreenComponent} />
    </Switch>
  );
};

export default withRouter(Routes);
