import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelRounded';
import PropTypes from 'prop-types';
import _without from 'lodash/without';
import './MultipleSelect.scss';
import { validate } from 'lib/utils-library';

const MultipleSelect = ({
  d3Data,
  items,
  value = [],
  onChange = () => {},
  onBlur = () => {},
  err = '',
}) => {
  const [currValue, setValue] = useState(value || d3Data.defaultValue || []);
  const [error, setError] = useState(err || null);

  useEffect(() => {
    setValue(value || d3Data.defaultValue || [])
  }, [d3Data.defaultValue]);

  const handleChange = (val) => {
    setValue(val);
    onChange(val);
    const newError = validate(val, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };
  const handleBlur = (val) => {
    onBlur(val);
    const newError = validate(currValue, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
  };
  return (
    <div>
      <FormControl error={Boolean(err) !== false || error != null} fullWidth>
        <InputLabel id='demo-multiple-chip-label'>{d3Data.name}</InputLabel>
        <Select
          type={d3Data.type}
          key={d3Data.key}
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={currValue}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => handleBlur(e.target.value)}
          input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
          renderValue={(selected) => (
            <Box id='box'>
              {selected.map((val) => (
                <Chip
                  key={val}
                  label={val}
                  deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={(e) =>
                    setValue(_without(currValue, e.nativeEvent.path[2].childNodes[0].innerHTML))
                  }
                />
              ))}
            </Box>
          )}
        >
          {(items || []).map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={currValue.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{err || error}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;

MultipleSelect.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

MultipleSelect.defaultProps = {
  onBlur: () => null,
  err: '',
  value: [],
};
