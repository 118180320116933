module.exports = {
  fieldType : {
    text: 'TextField',
    email: 'TextField',
    password: 'TextField',
    number: 'TextField',
    // Calc: 'Text',
    autoComplete: 'AutoComplete',
    time: 'Date',
    switch: 'Switch',
    checkbox: 'Checkbox',
    datePicker: 'DatePicker',
    dateRangePicker: 'DateRangePicker',
    dateTimePicker: 'DateTimePicker',
    multipleCheckbox: 'MultipleCheckbox',
    multipleSearchSelect: 'MultipleSearchSelect',
    multipleSelect: 'MultipleSelect',
    searchSelect: 'SearchSelect',
    select: 'Select',
    timePicker: 'TimePicker',
  }
}