import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import MessageHandling from 'screenComponents/MessageHandling';
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import Routes from './routing/Routes';

type Props = {
  basename: string,
}

const App: React.FC<Props> = ({basename}) => {  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
              <Routes />
              <MessageHandling />
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
