import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, Autocomplete, OutlinedInput } from '@mui/material';
import { validate } from 'lib/utils-library';
import ErrorTooltip from '../../ErrorTooltip';
import { mls } from 'lib/multilanguagesupport';

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleChange = (e, val) => {
    const newError = validate(val.value, props.column.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
      setValue(val.value);
    }
  };
  const handleBlur = (val) => {
    const newError = validate(val, props.column.validation);
    if (newError) {
      setError(newError.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleInputChange = (e, inputVal) => {
    setInputValue(inputVal);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    afterGuiAttached: () => {},
  }));

  return (
    <Autocomplete
      options={props.colDef.cellEditorParams.columnData?.selectMenu || []}
      value={value}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      autoComplete
      onBlur={handleBlur}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      disableClearable
      renderInput={(params) => (
        <ErrorTooltip
          open={Boolean(error)}
          title={error == null ? '' : error}
          arrow
        >
          <TextField
            {...params}
            size="small"
            placeholder={`${mls('Select')} ${props.colDef.cellEditorParams.columnData?.name}`}
          />
        </ErrorTooltip>
      )}
    />
  );
});
