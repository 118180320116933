const data = [
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jackbsmdbamsbdmabmd@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jack@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jack@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jack@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jack@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'john@gmail.com',
    FirstName: 'John',
    Salary: 500000,
    Years: 15,
    TotalSalary: 7500000,
    Designation: 'Manager',
    Active: true,
    Date: new Date('2021-10-11').toLocaleDateString(),
  },
  {
    EmailID: 'jack@gmail.com',
    FirstName: 'Jack',
    Salary: 400000,
    Years: 12,
    TotalSalary: 4800000,
    Designation: 'Employee',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
  {
    EmailID: 'jimmy@gmail.com',
    FirstName: 'Jimmy',
    Salary: 800000,
    Years: 10,
    TotalSalary: 8000000,
    Designation: 'Vice President',
    Active: false,
    Date: new Date('2021-10-21').toLocaleDateString(),
  },
];

// input pagesize
// multiple checkbox true false null multiselect select
// convert in the form of __d3__filter

export const getFilteredData = (column, value) => (
  value
    ? data.filter((row) => value.includes(row[column].toString()))
    : data
);

export default data;
