import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { validate } from 'lib/utils-library';
import 'bootstrap/dist/css/bootstrap.min.css';

const Switch = ({
  d3Data, value = false, onChange = () => {}, onBlur = () => {}, err = '',
}) => {
  const [currValue, setValue] = useState(value || d3Data.defaultValue || false);
  const [error, setError] = useState(err || null);

  useEffect(() => {
    setValue(value || d3Data.defaultValue || false);
  }, [d3Data.defaultValue]);

  const handleChange = (checked) => {
    setValue(checked);
    onChange(checked);
    const newError = validate(checked, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleBlur = () => {
    onBlur(currValue);
    const newError = validate(currValue, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
  };

  return (
    <div className="row">
      <label className="col-sm-4 col-lg-3 col-form-label">{d3Data.name}</label>
      <div className="col-sm-8 col-lg-9" style={{height: '45px'}}>
        <FormControl error={Boolean(err) !== false || error != null}>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={d3Data.columnName}
              checked={currValue}
              onChange={(e) => handleChange(e.target.checked)}
              onBlur={handleBlur}
            />
          </div>
          <FormHelperText>{err || error}</FormHelperText>
        </FormControl>
      </div>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

Switch.defaultProps = {
  value: false,
  onBlur: () => null,
  onChange: () => null,
  err: '',
};
