import useStoreSchema from "commons/useStoreSchema";
import AgGridWrapper from "lib/ag-grid-table";
import { mls } from "lib/multilanguagesupport";
import reduxConstants from "redux/reduxConstants";
import getComponentSchema from "screenComponents/getComponentSchema";

const HasMany = ({ relationSchema, dataTableSchema, componentID, parentRowID }) => {
    const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
    const { component, accessSchema, buttonSchema } = getComponentSchema(
        appSchema,
        componentID
      );
    return (
        <AgGridWrapper
        key={componentID}
        component={component}
        tableSchema={dataTableSchema}
        accessSchema={accessSchema}
        buttonSchema={buttonSchema}
        parentScreen={false}
        relationSchema={relationSchema}
        parentRowID={parentRowID}
    />);
}

export default HasMany;
