function getConnectedScreenGroup(appSchema, InnerViewComponentID, component, tableSchema) {
  const connectedScreenGroup = {};
  Object.keys(tableSchema?.relations || {})?.length > 0 && Object.keys(tableSchema.relations).forEach((relation) => {
    if (appSchema.appDatatable?.[tableSchema.relations[relation].target]) {
      connectedScreenGroup[relation] = {
        relationSchema: tableSchema.relations[relation],
        dataTableSchema: appSchema.appDatatable?.[tableSchema.relations[relation].target],
      };
    }
    return null;
  });
  return { connectedScreenGroup };
}

export default getConnectedScreenGroup;
