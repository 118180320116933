import React from 'react';
import constants from '../ag-grid-table/commons/constants';

import TextField from 'lib/input-components/TextField';
import Switch from 'lib/input-components/Switch';
import Checkbox from 'lib/input-components/Checkbox';
import DatePicker from 'lib/input-components/DatePicker';
import DateRangePicker from 'lib/input-components/DateRangePicker';
import DateTimePicker from 'lib/input-components/DateTimePicker';
import MultipleCheckbox from 'lib/input-components/MultipleCheckbox';
import MultipleSearchSelect from 'lib/input-components/MultipleSearchSelect';
import MultipleSelect from 'lib/input-components/MultipleSelect';
import SearchSelect from 'lib/input-components/SearchSelect';
import Select from 'lib/input-components/Select';
import TimePicker from 'lib/input-components/TimePicker';

const noColumns = ['__d3__CreatedAtDate', '__d3__CreatedAtTime', '__d3__UpdatedAtDate', '__d3__UpdatedAtTime'];

const components = {
  TextField,
  Switch,
  Checkbox,
  DatePicker,
  DateRangePicker,
  DateTimePicker,
  MultipleCheckbox,
  MultipleSearchSelect,
  MultipleSelect,
  SearchSelect,
  Select,
  TimePicker,
};

export function getComponent(columnData, onChange, onBlur, error) {
  if (columnData.dataType === 'belongsTo') return '';
  if (columnData.dataType != null && !noColumns.includes(columnData.dataType)) {
    if (typeof components[constants.fieldType[columnData.dataType]] !== 'undefined') {
      const InputComponent = components[constants.fieldType[columnData.dataType] || 'TextField'];
      return (
        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
          <InputComponent d3Data={columnData} onChange={onChange} onBlur={onBlur} error={error} />
        </div>
      );
    }
    return '';
  }
  return '';
}
