import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { validate } from 'lib/utils-library';

const TextField = ({ d3Data, value = '', onChange = () => {}, onBlur = () => {}, err = '' }) => {
  const [currValue, setValue] = useState(value || d3Data.defaultValue || '');
  const [error, setError] = useState(err || null);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef(null); // Ref to track the input element

  useEffect(() => {
    setValue(value || d3Data.defaultValue || '');
  }, [d3Data.defaultValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleValidation = (newVal) => {
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleChange = (newVal) => {
    setValue(newVal);
    onChange(newVal);
    handleValidation(newVal);
  };

  const handleBlur = (newVal) => {
    onBlur(newVal);
    handleValidation(newVal);
    setEditing(false);
  };

  return (
    <div className="row">
      <label htmlFor={d3Data.columnName} className="col-sm-4 col-lg-3 col-form-label">{d3Data.name}</label>
      <div className="col-sm-8 col-lg-9" style={{height: '45px'}}>
        {isEditing ? (
          <input
            ref={inputRef} // Attach the ref to the input element
            id={d3Data.columnName}
            className={`form-control ${err || error ? 'is-invalid' : ''}`}
            type={d3Data.datatype}
            value={currValue}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={(e) => handleBlur(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleBlur(e.target.value)}
            disabled={d3Data.disabled}
          />
        ) : (
          <span
            className="form-control-plaintext"
            style={{ textDecoration: currValue ? 'underline dotted' : 'underline dotted', cursor: 'pointer', color: currValue ? 'inherit' : '#009ef7' }}
            onClick={() => !d3Data.disabled && setEditing(true)}
          >
            {currValue || 'Click to edit'}
          </span>
        )}
        {(err || error) && <div className="invalid-feedback">{err || error}</div>}
      </div>
    </div>
  );
};

export default TextField;

TextField.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    datatype: PropTypes.string,
    defaultValue: PropTypes.string,
    validation: PropTypes.array,
    disabled: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

TextField.defaultProps = {
  value: '',
  onBlur: () => null,
  onChange: () => null,
  err: '',
};
