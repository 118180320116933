import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import AgGridWrapper from '..';
import { mls } from 'lib/multilanguagesupport';

function BelongsToManyModal({
  setModalOpen,
  modalOpen,
  tableConfig,
  component,
  createData,
  updateWithData,
  isUpdate,
  setRowsSelected,
  parentScreen,

  relationSchema = {},

  tableSchema,
  parentRowID,
  accessSchema,
  buttonSchema,
}) {
  const handleHideModal = () => {
    setModalOpen(false);
  };

  console.log(updateWithData, 'updateWithData')
  return (
    <Modal show={modalOpen} onHide={handleHideModal} size='xl'>
      {/* for has many create and update header in modal */}
      {

          <div class="modal-header ms-2">
            <h2 class="modal-title">
              {mls('Choose from the following rows:')}
            </h2>
            
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleHideModal}></button>
        </div>
        }
      <AgGridWrapper
              key={component.componentID}
              component={component}
              tableSchema={tableSchema}
              accessSchema={accessSchema}
              buttonSchema={buttonSchema}
              parentScreen={false}
              relationSchema={{ ...relationSchema, getAllNotSelected: true }}
              parentRowID={parentRowID}
          />
    </Modal>
  );
}

export default BelongsToManyModal;
