import { getComponent } from './getComponent';

export function ManageCreateData({ columnSchema, dataRef, error }) {
  const onChange = (columnName) => {
    return (value) => { dataRef.current = ({ ...dataRef.current, [columnName]: value }); };
  }
  const onBlur = (columnName) => {
    return (value) => { dataRef.current = ({ ...dataRef.current, [columnName]: value }); };
  }

  return (columnSchema || []).map((columnData) => {
      return getComponent({
        ...columnData,
        // adding default value and disabling if there it's a connected screen column
        defaultValue: dataRef.current[columnData.columnName] || columnData.defaultValue,
        // disabled: Boolean(connectedScreenParams[columnData.columnName]) || columnData.defaultValue
      },
      onChange(columnData.columnName),
      onBlur(columnData.columnName),
      error[columnData.columnName],
    );
    }
  );
}
