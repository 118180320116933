import { useRef, useState } from 'react';

import AgGrid from './AgGrid';
import Select from 'react-select';

/* eslint-disable jsx-a11y/anchor-is-valid */
import { mls } from 'lib/multilanguagesupport';
import TableExtraOptions from './TableExtraOptions';
import { Tooltip, IconButton } from '@mui/material';
import { Refresh, Delete, Edit } from '@mui/icons-material';
import CreateModal from './CreateModal';
import DeleteModal from './CreateModal/DeleteModal';
import useOperations from 'screenComponents/useOperations';
import useValidateResource from 'roles/validateResource';
import constants from 'commons/constants';
import { Link, useHistory } from 'react-router-dom';
import BelongsToManyModal from './belongsToManyModal/BelongsToManyModal';

const defaultPageSize = 10;

const AgGridWrapper = ({ component, tableSchema, buttonSchema, accessSchema, parentScreen, parentRowID = null, relationSchema = {} }) => {
  const operations = useOperations(component, null, false, relationSchema, parentRowID);
  const selectedNodesRef = useRef([]);
  const validateResource = useValidateResource();
  const [editableData, setEditable] = useState(false);
  const history = useHistory();
  // check for belongs to many relations case.
  const doesBelongsToMany = relationSchema?.type === 'belongsToMany';
  const doesGetAllNotSelected = relationSchema?.getAllNotSelected === true;
  // page-size state
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const total = operations.data?.count;

  // rowsSelected is false if no rows are selected else true
  const [rowsSelected, setRowsSelected] = useState(false);

  // checks whether to show popup or not
  const [isDeleting, setDeleting] = useState(false);

  // set create modal
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [belongsToManySelectModalOpen, setBelongsToManySelectModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  // callback function on click refresh button
  const handleRefresh = (event) => {
    // console.log(event, 'Fetching Data Again!');
    operations.refetch();
  };

  // callback function on click delete button
  const handleDelete = (event) => {
    setDeleting(true);
  };

  const handleUpdate = () => {
    setUpdateModalOpen(true);
  };

  const updateData = (data) => {
    if (editableData.id && data?.id) {
      const updateData = data;
      delete updateData.id;
      operations.updateData(updateData, editableData.id, false);
    }
  };

  const clearAllFilters = () => {
    operations.setFilterData({});
  };
  const handleDeleteSuccess = () => {
    setRowsSelected(false);
    selectedNodesRef.current = [];
  };
  const tableAccess = {
    create:
      validateResource(
        constants.resourceType.TABLE,
        component.table,
        constants.resourceType.CREATE
      ) !== false,
    read:
      validateResource(
        constants.resourceType.TABLE,
        component.table,
        constants.resourceType.READ
      ) !== false,
    update:
      validateResource(
        constants.resourceType.TABLE,
        component.table,
        constants.resourceType.UPDATE
      ) !== false,
    delete:
      validateResource(
        constants.resourceType.TABLE,
        component.table,
        constants.resourceType.DELETE
      ) !== false,
  };

  return (
    <div className='card shadow-sm mb-5 mb-xl-10'>
      <div className='card-body pt-2 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {relationSchema?.displayName || mls(component.name)}
                  </div>

                  {/* refresh button */}
                  <Tooltip title='Refresh'>
                    <IconButton onClick={handleRefresh}>
                      <Refresh color='primary' />
                    </IconButton>
                  </Tooltip>
                </div>

                {component.description && <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    {mls(component.description)}
                  </div>
                </div>}
              </div>

              <div className='d-flex my-4'>
                {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg'
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>{mls('CSV Import')}</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a> */}
                {tableAccess.create && parentScreen && (
                  <Link
                    to={`${history.location.pathname}/${component.componentID}`}
                  >
                    <button
                      className='btn btn-sm btn-primary me-3'
                      // onClick={() => setCreateModalOpen(true)}
                    >
                      {mls('Create')}
                    </button>
                  </Link>
                  
                )}

                {tableAccess.create && !parentScreen && !doesBelongsToMany && (
                 
                    <button
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => setCreateModalOpen(true)}
                    >
                      {mls('Create')}
                    </button>
                )}
                 {tableAccess.create && !parentScreen && doesBelongsToMany && !doesGetAllNotSelected && (
                    <button
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => setBelongsToManySelectModalOpen(true)}
                    >
                      {mls('Select')}
                    </button>
                )}
                {doesGetAllNotSelected && (
                    <button
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => (selectedNodesRef.current?.length > 0) && operations.createData({ __d3__id: selectedNodesRef.current})}
                    >
                      {mls('Add')}
                    </button>
                )}
                {createModalOpen && (
                  <CreateModal
                    modalOpen={createModalOpen}
                    setModalOpen={setCreateModalOpen}
                    tableConfig={tableSchema}
                    component={component}
                    parentScreen={parentScreen}
                    setRowsSelected={setRowsSelected}
                    relationSchema={relationSchema}
                    parentRowID={parentRowID}
                  />
                )}
                {updateModalOpen && (
                  <CreateModal
                    modalOpen={updateModalOpen}
                    setModalOpen={setUpdateModalOpen}
                    tableConfig={tableSchema}
                    component={component}
                    createData={updateData}
                    parentScreen={parentScreen}
                    updateWithData={editableData}
                    setRowsSelected={setRowsSelected}
                    relationSchema={relationSchema}
                    parentRowID={parentRowID}
                  />
                )}

                {belongsToManySelectModalOpen && (
                  <BelongsToManyModal
                    modalOpen={true}
                    setModalOpen={setBelongsToManySelectModalOpen}
                    tableConfig={tableSchema}
                    component={component}
                    createData={updateData}
                    parentScreen={parentScreen}
                    updateWithData={editableData}
                    setRowsSelected={setRowsSelected}
                    relationSchema={relationSchema}
                    tableSchema={tableSchema}
                    accessSchema={accessSchema}
                    buttonSchema={buttonSchema}
                    parentRowID={parentRowID}
                  />
                  )
                }
                {!doesBelongsToMany && <div className='me-0'>
                  <TableExtraOptions
                    tableSchema={tableSchema}
                    downloadCSV={operations.downloadCSV}
                    uploadCSV={operations.uploadCSV}
                  />
                </div>}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-wrap flex-sm-nowrap mb-1'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  {/* buttons will be invisible untill rows are selected */}
                  {rowsSelected && (
                    <div direction='row' spacing={2} alignItems='center'>
                      {/* delete button */}
                      {tableAccess.delete && !doesGetAllNotSelected && (
                        <Tooltip title={mls('Delete')}>
                          <IconButton onClick={handleDelete}>
                            <Delete color='error' />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isDeleting && (
                        <DeleteModal
                          deleteData={operations.deleteData}
                          setDeleting={setDeleting}
                          selectedNodes={selectedNodesRef.current}
                          handleDeleteSuccess={handleDeleteSuccess}
                        />
                      )}
                      {tableAccess.update && editableData && !doesBelongsToMany && (
                        <Tooltip title={mls('Edit')}>
                          <IconButton onClick={handleUpdate}>
                            <Edit color='primary' />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* additional buttons from the props */}
                      {buttonSchema.map(
                        (button) =>
                          validateResource(
                            constants.resourceType.BUTTONS,
                            button.actionButtonID
                          ) !== false && (
                            <button
                              key={button.name}
                              className={`btn btn-sm btn-${button.color || 'primary'} me-3`}
                              onClick={clearAllFilters}
                            >
                              {mls(button.name)}
                            </button>
                          )
                      )}
                    </div>
                  )}
                  <div
                    className='me-3 text text-primary'
                    style={{ cursor: 'pointer' }}
                    onClick={clearAllFilters}
                  >
                    {mls('Clear Filters')}
                  </div>
                </div>
              </div>

              <div className='d-flex'>
                {/* page size selector */}
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 my-2'>
                    {mls('Page Size')} :
                  </div>
                </div>
                <Select
                  options={[
                    { value: '10', label: '10' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' },
                  ]}
                  defaultInputValue={pageSize.toString()}
                  // value={pageSize.toString()}
                  onChange={(newValue) => {
                    setPageSize(parseInt(newValue.value) || defaultPageSize);
                    operations.setFilterData({
                      ...operations.filterData,
                      limit: newValue.value || defaultPageSize,
                    });
                  }}
                  placeholder=''
                />
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 my-2'>
                    <i
                      className='fas fa-chevron-left ms-2 fs-7 text-gray-600 text-hover-primary px-2'
                      data-bs-toggle='tooltip'
                      title={mls('Update the columns of this table.')}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                          operations.setFilterData({
                            ...operations.filterData,
                            offset: (currentPage - 2) * pageSize,
                          });
                        }
                      }}
                    />
                    {/* Current Page */}
                    {`${(currentPage - 1) * pageSize + 1} - ${
                      currentPage * pageSize > total ? total : currentPage * pageSize
                    } of ${total}`}
                    <i
                      className='fas fa-chevron-right ms-2 fs-7 text-gray-600 text-hover-primary px-2'
                      data-bs-toggle='tooltip'
                      title={mls('Update the columns of this table.')}
                      onClick={() => {
                        if (currentPage * pageSize < total) {
                          setCurrentPage(currentPage + 1);
                          operations.setFilterData({
                            ...operations.filterData,
                            offset: currentPage * pageSize,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tableAccess.read && (
          <AgGrid
            // tableAccess={tableAccess}
            tableSchema={tableSchema}
            setRowsSelected={setRowsSelected}
            rowData={operations.data?.rows || []}
            updateData={operations.updateData}
            selectedNodesRef={selectedNodesRef}
            filterData={operations.filterData}
            setFilterData={operations.setFilterData}
            setEditable={setEditable}
            parentScreen={parentScreen}
            componentID={component.componentID}
            noRowSelection={doesBelongsToMany && !doesGetAllNotSelected}
          />
        )}
        <div className='d-flex overflow-auto h-30px'></div>
      </div>
    </div>
  );
};

export default AgGridWrapper;
